import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Button} from "primereact/button";
import {Growl} from 'primereact/growl';
import './loginStyles.css';
import {BackendService} from "../service/wallet_backend/BackendService";
import {Checkbox} from "primereact/checkbox";
import {changeStyleSheetUrl, initialPage, loadProfile, validateKYCComplete} from "../util/commonFunctions";
import {InputText} from 'primereact/inputtext';



export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            usernameError: '',
            passwordError: '',
            login: false,
            errorLogin: '',
            messages: '',
            rand: Math.floor(Math.random() * (34 - 1)) + 1,
            height: window.innerHeight,
            usuario: this.datasource,
            visibleDialog: false,
            validando: false,
            redirectSignUp: false,
            checkedRemember: false,
        }
        this.items =  [
            {label: 'Why E-Wallet', icon: 'pi pi-fw pi-home'},
            {label: 'About Us', icon: 'pi pi-fw pi-calendar'},
            {label: 'Statistics', icon: 'pi pi-fw pi-pencil'},
            {label: 'Operational Capabilities', icon: 'pi pi-fw pi-file'}
        ];

        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.checkSession = this.checkSession.bind(this);
        //this.validarLogueo = this.validarLogueo.bind(this);
        this.showMsg = this.showMsg.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showError = this.showError.bind(this);
        this.showPassword = this.showPassword.bind(this);

        this.backendService = new BackendService();
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
    }

    sendLogin = () => {
        this.props.history.push("/login");
    }

    sendSignUp = () => {
        this.props.history.push("/signUp");
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.checkSession(e);
        }
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showError(msg) {
        let data = {severity: 'error', summary: 'ERROR', detail: msg};
        this.growl.show(data);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn(msg) {
        let data = {severity: 'warn', summary: 'AVISO', detail: msg};
        this.growl.show(data);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg) {
        let msg = {severity: severityMsg, summary: summaryMsg, detail: detailMsg};
        this.growl.show(msg);
    }

    /**
     * Metodo para autenticacion del usuario
     * Se utiliza el serivicio AuthService
     * params: usurname y password
     * return: mensaje de error o acceso al sistema
     **/
    checkSession = (e) => {
        this.setState({validando: true})
        e.preventDefault();
        if (this.isValidForm()) {
            this.validarLogueo(this);
        } else {
            this.setState({validando: false});
            this.showWarn();
        }
    }

    /**
     * Metodo de validacion de usuario y password no se encuentren vacios
     * params: usurname y password
     * return: true o false
     **/
    isValidForm() {
        let isValid = true;
        let usernameError = '';
        let passwordError = '';

        usernameError = this.state.username === '' ? 'El Usuario es obligatorio' : '';
        passwordError = this.state.password === '' ? 'La Contraseña es obligatorio' : '';

        isValid = usernameError === '' && passwordError === '';
        return isValid;
    }

    validarLogueo(self) {
        this.backendService.postLogin({
            username: self.state.username.toLowerCase(), password: self.state.password
        }).then(accessResp => {
            if (accessResp.access) {
                const expiration = (Date.now() / 1000) + 1000;

                localStorage.setItem('userNameSesion', self.state.username);
                localStorage.setItem("access_token", accessResp.access);
                localStorage.setItem("expires_date", expiration.toString());
                localStorage.setItem("refresh_token", accessResp.refresh);
                localStorage.setItem('login', true);
                loadProfile(self, self.backendService, null).then(resp => {
                    if (resp !== null) {
                        let lstMerchants = JSON.stringify(resp.profile.lstMerchants);
                        let role = resp.profile.rol;
                        let phone = resp.profile.cell_number;
                        const userLogged = resp.first_name + ' ' + resp.last_name;

                        localStorage.setItem('lstMerchants', lstMerchants);
                        localStorage.setItem('role', role);
                        localStorage.setItem('phone', phone);
                        localStorage.setItem('userLogged', userLogged);
                        this.backendService.getKYCStatus()
                            .then(kycResp => {
                                if (kycResp.status === 'success') {
                                    const documents = kycResp.data;
                                    localStorage.setItem('documents', JSON.stringify(documents));
                                }
                                self.setState({login: true});
                                if (validateKYCComplete() !== true) {
                                    self.props.history.push("/kyc");
                                } else {
                                    self.props.history.push(initialPage);
                                }
                            }).catch(error => {
                                self.setState({login: true});
                                self.props.history.push(initialPage);
                                if(self.growl !== null) {
                                    self.growl.show({severity: 'error', summary: 'Error', detail: "Error load KYC documents"});
                                }
                        });
                    } else {
                        self.setState({validando: false});
                        self.growl.show({severity: 'error', summary: 'Error', detail: "Bad credentials"});
                    }
                });
            }
        }).catch(error => {
            self.setState({validando: false});
            if(self.growl !== null){
                self.growl.show({severity: 'error', summary: 'Error', detail: "User or password is wrong"});
            }
        });
    }

    cleanDataApproved(data) {
        let sectionDict = data !== null ? data['IDENTIFICATION'] : null;
        let sectionDictIdent = data !== null ? data['PROOF_ADDRESS'] : null;

        if (sectionDict !== null) {
            if (sectionDict.hasOwnProperty('Face')) {
                sectionDict = this.cleanData(sectionDict, 'Face');
            }
            if (sectionDict.hasOwnProperty('Identity Card No.')) {
                sectionDict = this.cleanData(sectionDict, 'Identity Card No.');
            }
            if (sectionDict.hasOwnProperty('Identity Card')) {
                sectionDict = this.cleanData(sectionDict, 'Identity Card');
            }
            if (sectionDict.hasOwnProperty('id_card')) {
                sectionDict = this.cleanData(sectionDict, 'id_card');
            }
            if (sectionDict.hasOwnProperty('Passport')) {
                sectionDict = this.cleanData(sectionDict, 'Passport');
            }
            if (sectionDict.hasOwnProperty('passport')) {
                sectionDict = this.cleanData(sectionDict, 'passport');
            }
            if (sectionDict.hasOwnProperty('Driver License')) {
                sectionDict = this.cleanData(sectionDict, 'Driver License');
            }
            if (sectionDict.hasOwnProperty('driving_license')) {
                sectionDict = this.cleanData(sectionDict, 'driving_license');
            }
            data['IDENTIFICATION'] = sectionDict;
        }
        if (sectionDictIdent !== null) {
            if (sectionDictIdent.hasOwnProperty('Utility Bill')) {
                sectionDictIdent = this.cleanData(sectionDictIdent, 'Utility Bill');
            }
            if (sectionDictIdent.hasOwnProperty('utility_bill')) {
                sectionDictIdent = this.cleanData(sectionDictIdent, 'utility_bill');
            }
            if (sectionDictIdent.hasOwnProperty('Bank Statement')) {
                sectionDictIdent = this.cleanData(sectionDictIdent, 'Bank Statement');
            }
            if (sectionDictIdent.hasOwnProperty('bank_statement')) {
                sectionDictIdent = this.cleanData(sectionDictIdent, 'bank_statement');
            }
            data['PROOF_ADDRESS'] = sectionDictIdent;
        }
        return data;
    }

    cleanData(data, name) {
        if (data[name]['status_kyc_desc'] === "Approved") {
            data[name]['front_data_64'] = "";
            data[name]['front_data_64'] = "";
        }
        data[name]['front_data_64'] = "";
        data[name]['front_data_64'] = "";
        return data;
    }
    showPassword () {
            var tipo = document.getElementById("password");
            if(tipo.type === "password"){
                tipo.type = "text";
            }else{
                tipo.type = "password";
            }
            return false;
        }


    render() {
        if (this.state.login) {
            return (<Redirect to={`/`}/>);
        }

        return (
            <div style={{display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw', overflow: 'auto'}}>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}} />
                {/* Mitad 1 - Con la imagen */}
                <div  className="imgDiv" style={{flex: 1, position: 'relative', background: `url('assets/ewallet/img/img.png') no-repeat center center / cover`}}>
                    <img src='assets/ewallet/img/img_1.png' alt='Overlaid'
                         style={{
                             position: 'absolute',
                             bottom: 0,
                             left: 0,
                             opacity: 0.5
                         }} />
                </div>
                {/* Mitad 2 - Vacía */}
                <div className="outerDiv">
                    <div className="innerDiv">
                        <div style={{textAlign: 'right'}}>
                            <div style={{display: 'inline-block'}}>
                                <img src='assets/ewallet/img/img_2.png' alt='Gostax' style={{width: '60%'}}/>
                                <div style={{borderBottom: '1px solid black'}}>
                                    <p style={{
                                        width: '100%',
                                        fontSize: '26px',
                                        color: '#223035',
                                        marginTop: '0px'
                                    }}>Log In To Your GoStax Account</p>
                                </div>
                                <a href="#/signUp" onClick={this.sendSignUp} className="signup-image-link"
                                   style={{
                                       textDecoration: "underline" /*color: "#0062cc"*/,
                                       paddingBottom: "1px",
                                       fontSize: "20px"
                                   }}>Create an account</a>
                            </div>
                        </div>
                        <div className="form-group" style={{paddingTop: '2px'}}>
                            <label style={{
                                display: 'block',
                                color: '#555658',
                                fontFamily: '"Roboto", Sans-serif',
                                fontSize: 16,
                                fontWeight: 500,
                                lineHeight: '21px'
                            }}>Email Address</label>
                            <span className="p-input-icon-left p-fluid">
                                <InputText
                                    style={{color: 'black', height: '50px',paddingLeft: '0.6rem'}}
                                    value={this.state.username}
                                    placeholder="Enter Your Email Here"
                                    onKeyPress={this._handleKeyPress}
                                    onChange={(e) => this.setState({username: e.target.value})}
                                />
                            </span>
                        </div>

                        <div className="form-group">
                            <label
                                style={{
                                    display: 'block',
                                    color: '#555658',
                                    fontFamily: '"Roboto", Sans-serif',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    lineHeight: '21px'
                                }}
                            >
                                Password
                            </label>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <InputText
                                    name="password"
                                    value={this.state.password}
                                    placeholder="Enter Your Password Here"
                                    id="password"
                                    onKeyPress={this._handleKeyPress}
                                    type="password"
                                    onChange={(e) => this.setState({password: e.target.value})}
                                    style={{
                                        flexGrow: 1,
                                        color: 'black',
                                        height: '50px',
                                        borderRadius: '5px 0 0 5px',
                                        borderRight: 'none'
                                    }}
                                />

                                <Button
                                    icon="fa fa-eye"
                                    className="p-button-text"
                                    style={{
                                        height: '50px',
                                        backgroundColor: 'rgb(34, 48, 53)',
                                        borderRadius: '0px 5px 5px 0px',
                                        borderRightWidth: '0px',
                                        marginRight: '0px',
                                    }}
                                    onClick={this.showPassword}
                                />
                            </div>
                        </div>
                        <div className="form-group" style={{marginBottom: '10px'}}>
                            <Checkbox inputId="binary" checked={this.state.checkedRemember}
                                      onChange={e => this.setState({checkedRemember: e.checked})}/>
                            <label htmlFor="remember-me" style={{color: "#E08727"}}
                                   className="label-agree-term"><span><span></span></span>Remember
                                me</label>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 ta-right">
                                <Button label="Sign In" style={{
                                    fontSize: '16px',
                                    fontFamily: '"Roboto", Sans-serif',
                                    padding: '10px 20px',
                                    borderRadius: '40px',
                                    backgroundColor: '#223035',
                                    boxShadow: '0px 3px 6px #00000029',
                                    width: '150px'
                                }}
                                        className={this.state.validando ? 'secondary-btn' : "p-button-primary"}
                                        disabled={this.state.validando} onClick={this.checkSession}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 ta-left">
                                <a href="/#/forgot_password">Forgot Password?</a>
                                <p style={{
                                    color: '#717475',
                                    fontFamily: '"Roboto", Sans-serif',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '17px',
                                }}>
                                    Be cautious when sharing your GoStax credentials. If additional parties need to
                                    access your account(s), please visit the settings section within your GoStax online
                                    portal for information on user permissions.
                                    <br/>
                                    For support logging into your GoStax account, email us at support@gostax.com for
                                    assistance.
                                    <br/><br/>
                                    © Copyright. Go Stax Group, LLC, 2023
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
