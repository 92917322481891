import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Messages} from "primereact/messages";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {MERCHANT_KEY} from "../../util/constants";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Growl} from "primereact/growl";
import {hideModal, isEmpty, loadProfile, validateEmail} from "../../util/commonFunctions";
import {ValidationKYC} from "../KYC/ValidationKYC";
import {InputMask} from "primereact/inputmask";

export class Register_Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts:[],
            dataTableSelection:{},
            registerDialogDisplay: false,
            displayConfirm: false,
            actionToConfirm: null,
            processService: false,
            alias: "",
            bank_account_email: "",
            bank_account_holder_name: "",
            currency: "USD",
            currencies: [{label: 'Select Currency', value: ''},
                {label: 'USD', value: 'USD'}],
            confirmDeleteDisplay: false,
            requestFundsDialogDisplay: false
        }
        this.backendService = new BackendService();
        this.loadAccounts = this.loadAccounts.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.addAccountSubmitHandler = this.addAccountSubmitHandler.bind(this);
        this.validateNewAccount = this.validateNewAccount.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.transferSubmitHandlerNew = this.transferSubmitHandlerNew.bind(this);
    }

    componentDidMount() {
        this.loadAccounts();
    }

    loadAccounts = () => {
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const rowTo = profileData.array_accounts;
        this.setState({rowTo})
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
        }
        this.backendService.getAccountList(params).then(resp => {
            if (resp.status === 'Success') {
                const accounts = [];
                resp.data.forEach(element => {
                    element.d_created = element.d_created.substring(0, 10);
                    accounts.push(element)
                });
                this.setState({accounts});
            } else {
                this.messages.show({severity: 'warning', summary: 'Warning', detail: "Error in Service"});
            }
        }).catch(error => {
            if (this.messages !== null) {
                this.messages.show({
                    severity: 'warning',
                    summary: 'Warning',
                    detail: `Error in Service recipients list`
                });
            }
        });
    }

    actionBodyTemplate() {
        return (
            <div style={{display: 'flex'}}>
                <Button onClick={this.showModal('confirmDeleteDisplay')}
                        className="p-button-warning"
                        style={{marginRight: '10px', marginLeft: '20px'}} label="Delete" icon="pi pi-trash">
                </Button>
                <Button label="Send" icon="pi pi-money-bill"
                        onClick={this.showModal('requestFundsDialogDisplay')}
                        className="p-button-warning"/>
            </div>
        )
    }

    addAccountSubmitHandler = e => {
        e.preventDefault();
        if (this.validateNewAccount()) {
            const profileData = JSON.parse(localStorage.getItem('profile'));
            const params = {
                merchant_key: MERCHANT_KEY,
                username: profileData.email,
                alias: this.state.alias,
                bank_account_email: this.state.bank_account_email,
                bank_account_holder_name: this.state.bank_account_holder_name,
                currency: this.state.currency
            }

            this.backendService.addRegisterAccount(params).then(resp => {
                if (resp.status === 'Success') {
                    this.loadAccounts();
                    this.messages.show({severity: 'success', summary: 'Success', detail: 'pending Wallet Added Successfully!'});
                } else {
                    this.growl.show({severity: 'warning', summary: 'Warning', detail: "Error in Service Link Wallet Account"});
                }
                this.setState({
                    wallet_name: '',
                    registerDialogDisplay: false,
                });
            }).catch(error => {
                this.setState({
                    wallet_name: '',
                    registerDialogDisplay: false,
                });
                if (this.growl !== null) {
                    this.growl.show({
                        severity: 'warning',
                        summary: 'Warning',
                        detail: `Error in Service Link Wallet Account`
                    });
                }
            });
        }
    };

    validateNewAccount() {
        if (isEmpty(this.state.alias) || isEmpty(this.state.bank_account_email) ||
            isEmpty(this.state.bank_account_holder_name) || isEmpty(this.state.currency)) {
            this.growl.show({severity: 'error', summary: 'Error', detail: 'Please fill all fields!'});
            return false;
        } else if (!validateEmail(this.state.bank_account_email)) {
            this.growl.show({severity: 'error', summary: 'Error', detail: 'Email not valid.'});
            return false;
        } else {
            return true;
        }
    }

    deleteConfirm() {
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
            register_account_id: this.state.dataTableSelection.id
        }

        this.backendService.removeRegisterAccount(params).then(resp => {
            if (resp.status === 'Success') {
                this.loadAccounts();
                this.setState({confirmDeleteDisplay: false});
                this.messages.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `Account deleted id: ${this.state.dataTableSelection.id} - Alias: ${this.state.dataTableSelection.alias}`,
                    life: 3500});
            } else {
                this.messages.show({severity: 'warning', summary: 'Warning', detail: "Error in Service Link Wallet Account"});
            }
        }).catch(error => {
            if (this.messages !== null) {
                this.messages.show({
                    severity: 'warning',
                    summary: 'Warning',
                    detail: `Error in Service Link Wallet Account`
                });
            }
        });

    }

    showModal = modal => () => {
        this.setState({[modal]: true});
    }
    hideModal = modal => () => {
        this.setState({
            [modal]: false, displayConfirm: false,
        });
    }
    confirmationDialog = (nameModal, showModal, action) => {
        if(nameModal === "registerDialogDisplay" && !this.validateNewAccount()){
            return;
        } else {
            this.setState({
                [showModal]: true, actionToConfirm: action
            })
        }

    }
    changeHandler = event => {
        const value = event.target.value;
        if (event.target.name === 'amountToTransfer') {
            if (value === "" || Number.isInteger(+value)) {
                this.setState({[event.target.name]: value});
            }
        } else {
            this.setState({[event.target.name]: event.target.value});
        }
    }


    itemTemplate(option) {
        return (
            <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {option.label}
                    <span style={{color: "#E0C46C"}}>${option.last_balance}</span>
                </div>
            </React.Fragment>
        );
    }
    transferSubmitHandlerNew = e => {
        e.preventDefault();

        const {amountToTransfer, sender_account, receiver_account, wallet_reference} = this.state;

        if (amountToTransfer === '' || sender_account === '' || receiver_account === '' || wallet_reference === '') {
            this.growl.show({severity: 'error', summary: 'Error', detail: 'Please give a valid info!'});
        } else {
            //const profileData = JSON.parse(localStorage.getItem('profile'));
            const params = {
                //merchant_key: MERCHANT_KEY,
                //username: profileData.email,
                sender_account: sender_account.provider_accountid,
                receiver_account: this.state.dataTableSelection.id,
                amount: amountToTransfer,
                reference: wallet_reference,
                //test: true
            }
            this.backendService.postTransferAccountBest(params).then(resp => {
                if (resp.status === 'Success') {
                    loadProfile(this, this.backendService, null).then(resp => {
                        if (resp !== null) {
                            this.loadAccounts(resp, this);
                        }
                    });
                    this.setState({
                        requestFundsDialogDisplay: false,
                        amountToTransfer: '',
                        sender_account: '',
                        receiver_account: '',
                        wallet_reference: '',
                    });
                    this.messages.show({severity: 'success', summary: 'Success', detail: 'Coin Moved Successfully!'});
                } else {
                    this.growl.show({severity: 'warning', summary: 'Warning', detail: 'Error in Service'});
                }
            }).catch(error => {
                this.setState({requestFundsDialogDisplay: false});
                const errorObjectShow = `CATCH: ${JSON.stringify(
                    error.response.data.message,
                )}`;
                this.growl.show({
                    severity: 'warning',
                    summary: 'Warning',
                    detail: `Error in Service recipients list ${errorObjectShow}`
                });
            });
        }
    };

    render() {

        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processService}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processService}/>
            </div>
        );
        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '350px', background: "#1c2155"}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Yes, continue</p>
            </Dialog>
        );
        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction}/>
            </div>
        );

        const registerDialog = (
            <Dialog header="Add a Friend Wallet" visible={this.state.registerDialogDisplay} modal={true}
                    style={{width: '450px', background: "#1c2155"}}
                    footer={dialogFooter('registerDialogDisplay', this.addAccountSubmitHandler, 'Register')}
                    onHide={this.hideModal('registerDialogDisplay')}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="alias" className="p-col-fixed" style={{width: '120px'}}>Nickname:</label>
                        <div className="p-col">
                            <InputText value={this.state.alias}
                                       placeholder="Nickname"
                                       name="alias"
                                       onChange={this.changeHandler}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="currency" className="p-col-fixed" style={{width: '120px'}}>Currency:</label>
                        <div className="p-col">
                            <Dropdown
                                options={this.state.currencies}
                                value={this.state.currency}
                                placeholder="Select currency"
                                name="currency"
                                style={{height: 30}}
                                className="slimDropdown"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="account_email" className="p-col-fixed" style={{width: '120px'}}>Account
                            Email:</label>
                        <div className="p-col">
                            <InputText value={this.state.bank_account_email}
                                       placeholder="Account Email"
                                       name="bank_account_email"
                                       onChange={this.changeHandler}
                            />
                        </div>
                    </div>
                    {/*<div className="p-field p-grid p-fluid">
                        <label htmlFor="bank_account_holder_name" className="p-col-fixed" style={{width: '120px'}}>Wallet
                            name:</label>
                        <div className="p-col">
                            <InputText value={this.state.bank_account_holder_name}
                                       placeholder="Wallet name"
                                       name="bank_account_holder_name"
                                       onChange={this.changeHandler}
                            />
                        </div>
                    </div>*/}
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="bank_account_holder_name" className="p-col-fixed" style={{width: '120px'}}>Wallet
                            name:</label>
                        <div className="p-col">
                            <InputMask id="wallet" mask="WALLET-999" value={this.state.bank_account_holder_name} placeholder="WALLET-9999" onChange={(e) => this.setState({bank_account_holder_name: e.value})}></InputMask>
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const confirmDeleteDialog = (
            <Dialog header="Are you sure?"
                    visible={this.state.confirmDeleteDisplay} modal={true}
                    style={{width: '400px', background: "#1c2155"}}
                    contentStyle={{height: 100}}
                    footer={dialogFooter('confirmDeleteDisplay', this.deleteConfirm, "Delete")}
                    onHide={this.hideModal('confirmDeleteDisplay')}>
                <label className="text-big">Confirm delete to</label>
                <p>Holder Name: {this.state.dataTableSelection.bank_account_holder_name} - Alias: {this.state.dataTableSelection.alias}</p>
            </Dialog>
        );
        const dialogFooterSend = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction}/>
            </div>
        );

        const requestFundsDialog = (
            <Dialog header="Send Funds" visible={this.state.requestFundsDialogDisplay} modal={true}
                    style={{width: '550px', background: "#1c2155"}}
                    contentStyle={{height: 250}}
                    footer={dialogFooterSend('requestFundsDialogDisplay', this.transferSubmitHandlerNew, 'SEND')}
                    onHide={hideModal('requestFundsDialogDisplay', this)}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="from" className="p-col-fixed" style={{width: '160px'}}>From:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                options={this.state.rowTo}
                                itemTemplate={this.itemTemplate}
                                optionLabel="label"
                                value={this.state.sender_account}
                                placeholder="Sender account"
                                name="sender_account"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="to" className="p-col-fixed" style={{width: '160px'}}>To:</label>
                        <div className="p-col">
                            <InputText
                                scrollHeight="100px"
                                value={this.state.dataTableSelection.alias}
                                placeholder="Select receiver account"
                                name="receiver_account"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="amountToTransfer" className="p-col-fixed"
                               style={{width: '160px'}}>Amount:</label>
                        <div className="p-col">
                            <InputText value={this.state.amountToTransfer}
                                       placeholder="Amount"
                                       type="number"
                                       name="amountToTransfer"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Reference:</label>
                        <div className="p-col">
                            <InputText value={this.state.wallet_reference}
                                       placeholder="Reference"
                                       name="wallet_reference"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );


        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4">
                                <h2 className="mb-20">Add a Friend Wallet</h2>
                            </div>
                            <div className="p-col-8 text-right">
                                <Button label="Add Wallet" icon="fa fa-plus-square-o"
                                        onClick={this.showModal('registerDialogDisplay')}/>
                            </div>
                            <div className="p-col-12">
                                <h2>Instructions:</h2>
                                <ol type="1">
                                    <li>Alias: Give the linked account a nickname so you can easily recognize it</li>
                                    <li>Email: Enter the Email address (used in the wallet) of the account you are linking</li>
                                    <li>Holder Name: Enter the users name (registered in the wallet) of the account you are linking</li>
                                    <li>Currency: Select the currency (default is USD)</li>
                                </ol>
                            </div>
                            <div className="p-col-12">
                                <Messages ref={(el) => this.messages = el}></Messages>
                            </div>
                            <div className="p-col-12">
                                <DataTable value={this.state.accounts} selectionMode="single" paginator={true} rows={10}
                                           selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                                           responsive={true}>
                                    <Column field='alias' header='Nickname' className="ta-center"/>
                                    <Column field='bank_account_email' header='Email' className="ta-center"/>
                                    <Column field='bank_account_holder_name' header='Wallet Name' className="ta-center"/>
                                    <Column field='currency' header='Currency' className="ta-center" style={{width:'100px'}}/>
                                    <Column field='d_created' header='Created' className="ta-right" style={{width:'100px'}}/>
                                    <Column body={this.actionBodyTemplate} header='Action' style={{width:'200px'}} className="ta-center"/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
                {confirmDialog}
                {registerDialog}
                {confirmDeleteDialog}
                {requestFundsDialog}
                <ValidationKYC history={this.props.history}/>
            </div>
        );
    }
}
